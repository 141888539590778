//https://cdnjs.cloudflare.com/ajax/libs/smooth-scroll/16.1.0/smooth-scroll.min.js

var smoothscroll = new SmoothScroll('a[href*="#"]', {
  header: '[data-scroll-header]',
  speed: 300,
  offset: 0,
  easing: 'easeInOutCubic'
});

var smoothScrollWithoutHash = function (selector, settings) {
  var clickHandler = function (event) {
    // console.log(document.querySelector('#header'));

    var toggle = event.target.closest(selector);
    if (!toggle || toggle.tagName.toLowerCase() !== 'a') return;
    var anchor = document.querySelector(toggle.hash);
    if (!anchor) return;
    event.preventDefault();
    smoothscroll.animateScroll(
      anchor,
      toggle,
      options || { header: '[data-scroll-header]' }
    );
    _state = false;
  };
  window.addEventListener('click', clickHandler, false);
};
smoothScrollWithoutHash('a[href*="#"]');

// モーダルウィンドウを開く
$('.js-modal-open').on('click', function(){
  let target = $(this).data('target');
  $('#' + target + '').children('.modal__contents').fadeIn();
  console.log(target);
});

// モーダルウィンドウを閉じる
$('.js-modal-close').on('click', function(){
  $('.js-modal').children('.modal__contents').fadeOut();
});

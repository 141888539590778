$(function ($) {
  $('.js-accordion-title').on('click', function () {
    /*クリックでコンテンツを開閉*/
    $('.o-contents_side').removeClass('-close', 0);
    $('.o-contents_main').removeClass('-close', 0);

    $(this)
      .next()
      .slideToggle(0);
      // .slideToggle(200);
    /*矢印の向きを変更*/
    $(this).toggleClass('open', 200);
  });
  $('.js-sidebar-toggle').on('click', function () {
    $('.js-accordion-title').removeClass('open', 0);
    $('.m-accordion-content').hide();

    $('.o-contents_side').toggleClass('-close', 200);
    $('.o-contents_main').toggleClass('-close', 200);
  });
});

$(window).on('load resize', function () {
  autoToggleSideNav();
});
function autoToggleSideNav() {
  // 処理を記載
  if ($(window).innerWidth() < 1200) {
    $(".m-accordion-content").css('display','none')
    $('.o-contents_side').addClass('-close', 200);
    $('.o-contents_main').addClass('-close', 200);
  } else {
    $('.o-contents_side').removeClass('-close', 200);
    $('.o-contents_main').removeClass('-close', 200);
  }
}

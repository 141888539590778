// // 'use strict';

var _MenuButton = document.getElementsByClassName('js-menu-toggle');
var _state = false;
var _scrollfromTop;
var _dataAsClick;

menu_init();
function menu_init () {
  for (var $i = 0; $i < _MenuButton.length; $i++) {
    _MenuButton[$i].addEventListener(
      'click',
      function () {
        _scrollfromTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        document.body.classList.toggle('js-menu_active');

        if (_state == false) {
          //hirakutoki
          _dataAsClick = _scrollfromTop; //else内でも使うためオープンで
          document.documentElement.style.height = 'auto';
          document.body.style.top = -_dataAsClick + 'px';
          _state = true;
        } else {
          document.documentElement.style.height = '100%';
          document.body.style.top = '0px';
          window.scrollTo(0, _dataAsClick);
          _state = false;
        }
      },
      false
    );
  }
} //menu_init()
